<template>
  <div class="ContentWrapDefault">
    <div class="ContentWrapDefault-cont">
      <slot name="ja" />
    </div>
    <div :class="{ '-isHideSp': !$slots.en }" class="ContentWrapDefault-cont">
      <slot name="en" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.ContentWrapDefault {
  @include flex();
  @include box-mg(null, $cont-wrap-right);

  gap: 48px;
  max-width: $cont-wrap;

  @include mq(md) {
    @include box-mg(null, $cont-wrap-right-sp);

    display: block;
  }

  > #{&}-cont {
    flex: 1;

    @include mq(md) {
      + * {
        @include box-mg(56px);
      }
    }

    @include mq(md) {
      &.-isHideSp {
        display: none;
      }
    }
  }
}
</style>
